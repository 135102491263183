import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { interval, Observable, Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'timer-action',
  standalone: true,
  imports: [],
  templateUrl: './timer-action.component.html',
  styleUrl: './timer-action.component.css',
})
export class TimerActionComponent implements OnInit, OnDestroy {
  @Input() intervalo?: number = 5000; //padrão 5s
  @Input() isActiveAction: boolean = true;
  @Output() onTrigger = new EventEmitter();

  private interval$?: Observable<number>;
  private unsubscribe$ = new Subject<void>();

  constructor(private readonly authService: AuthenticationService) {}

  ngOnInit(): void {
    this.interval$ = interval(this.intervalo).pipe(
      takeUntil(this.unsubscribe$)
    );

    this.interval$.subscribe(() => this.action());

    console.log('[ TimerAction ] ativado');
  }
  ngOnDestroy(): void {
    this.turnOff();
  }

  turnOn() {}

  turnOff() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    console.log('[ TimerAction ] desligado');
  }

  action() {
    // console.log('[ TimerAction ] inProgress?', this.isActionProgress);

    const _autenticado = this.authService.isAuthenticated;

    // if(!_autenticado) this.turnOff();

    if (this.isActiveAction) this.onTrigger.emit();
  }
}
